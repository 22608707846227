// import libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// import icons
import LeftArrow from "../../assets/images/left_arrow.svg";
import TextIcon from "../../assets/images/text_icon.svg";
import ImageIcon from "../../assets/images/image_icon.svg";
import DocIcon from "../../assets/images/doc_icon.svg";
import PdfIcon from "../../assets/images/pdf_icon.svg";

// import CSS file for styling
import { Container, Content, SidebarIcon } from "./styles";
import "./index.css";

// import components
import SidebarItem from "../SidebarItem";

// import controller object for api calling
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import UploadLinkButton from "../Commoncomponent/UploadButton/UploadLinkButton";
import Settingmodal from "../Commoncomponent/Modals/Settingmodal";
import { Spinner } from "reactstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Sidebar = ({
  loginstatus,
  active,
  openSidebar,
  sidebarStatus,
  onItemClick,
  onIdClick,
  isStillUploading,
  setIsStillUploading,
}) => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [sidebarOpened, setSidebarOpened] = useState(false); // State to track if sidebar is opened
  const [selectedFiles, setSelectedFile] = useState([]);
  const [fileExtensionToIconMap, setFileExtensionToIconMap] = useState({
    ".pdf": PdfIcon,
    ".png": ImageIcon,
    ".txt": TextIcon,
    ".doc": DocIcon,
    ".jpg": ImageIcon,
    ".jpeg": ImageIcon,
  });
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [isUploading, setIsUploading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (active && sidebarStatus) {
      // Open the sidebar after a 0.2-second delay

      const timer = setTimeout(() => {
        setSidebarOpened(true);
      }, 150);
      // Clear the timer if the component unmounts or if the sidebar is closed
      return () => clearTimeout(timer);
    } else {
      setSidebarOpened(false);
    }
  }, [active, sidebarStatus]);

  const closeSidebar = () => {
    active(false);
  };

  const OpenSidebar = () => {
    openSidebar();
  };

  const queryClient = useQueryClient();

  const { data: fileList, isFetching } = useQuery({
    queryKey: [`file-list`],
    queryFn: () => authDataObject.AdminFileList(),
    enabled: loginstatus === true,
    onSuccess: () => {
      setInitialLoadComplete(true);
      setIsPatch(false);
      setIsUploading(false);
      setIsStillUploading(false);
      setIsDeleting(false);
    },
  });

  useEffect(() => {
    if (fileList) {
      const docList = fileList?.data?.details?.document_list;
      setSelectedFile(docList);
    }
  }, [fileList]);

  const [isPatch, setIsPatch] = useState(false);
  const mutation = useMutation(authDataObject.patchFileOpenStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(["file-list"]);
      queryClient.invalidateQueries([`current-usage-nav-bar`]);
    },
  });

  const CloseIconWrapper = styled.div`
    height: 44.5px !important;
    display: flex;
    background-color: #fff;
    z-index: -1;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    color: rgb(154, 149, 149);
    font-family: "League Spartan";
    font-size: 13px;
    > img {
      position: absolute;
      color: black;
      width: 20px;
      height: 20px;
      top: 12px;
      right: 10px;
      cursor: pointer;
      &:hover {
        top: 8px;
        right: 6px;
        cursor: pointer;
        background-color: #e9e1e1;
        border-radius: 25%;
        width: 28px;
        height: 28px;
        padding: 4px;
      }
    }
  `;

  return (
    <Container active={active} sidebarStatus={sidebarStatus}>
      {window.innerWidth <= 770 ? null : (
        <CloseIconWrapper>
          {window.innerWidth <= 770 ? null : (
            <SidebarIcon onClick={OpenSidebar} sidebarStatus={sidebarStatus} />
          )}
          {active && sidebarStatus && sidebarOpened ? (
            <img src={LeftArrow} alt="left arrow" onClick={closeSidebar} />
          ) : null}
        </CloseIconWrapper>
      )}
      <div className="linesaprate"></div>
      <Content>
        {loginstatus && (
          <UploadLinkButton
            sidebarStatus={sidebarStatus}
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        )}
        {isStillUploading || isUploading ? (
          <div className="spinner">
            <Spinner
              size={"sm"}
              color="secondary"
              className="m-1 spinner-loader"
            />
            {sidebarStatus ? (
              <span className="spinner-text">Uploading...</span>
            ) : null}
          </div>
        ) : null}
        {/* {isFetching && !isUploading && !isStillUploading && !isPatch ? (
          <div className="spinner">
            <Spinner
              size={"sm"}
              color="secondary"
              className="m-1 spinner-loader"
            />
            {sidebarStatus ? (
              <span className="spinner-text">Loading...</span>
            ) : null}
          </div>
        ) : null} */}
        {!initialLoadComplete && isFetching ? (
          <div
            style={{
              height: "100vh",
              width: " 100%",
              position: "fixed",
              top: 65,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: "1050",
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(1.5px)",
            }}
          >
            <Spinner
              className="m-1 spinner-loader"
              color="secondary"
              style={{
                width: "50px",
                height: "50px",
                zIndex: "102",
              }}
            />{" "}
            <span
              style={{
                color: "#6c757d",
              }}
            >
              Please wait...
            </span>
          </div>
        ) : null}

        <Settingmodal
          isOpen={isModalOpen}
          toggleModal={() => setIsModalOpen(!isModalOpen)}
          // onStatusTrue={() => setIsUploading(!isUploading)}
          setIsFileUploading={(val) => setIsUploading(val)}
        />

        {selectedFiles?.length > 0
          ? selectedFiles?.map((file, index) => {
              const isSelected =
                activeComponent === `selectedFileViewer_${file.document_id}`;
              return (
                <SidebarItem
                  Icon={fileExtensionToIconMap[file.document_type]}
                  key={index}
                  file={file}
                  active={sidebarStatus}
                  width={
                    active ? "190px" : window.innerWidth <= 770 ? "0px" : "50px"
                  }
                  selected={isSelected}
                  onClick={() => {
                    setActiveComponent(
                      `selectedFileViewer_${file.document_id}`
                    );
                    onItemClick(file);
                    onIdClick(file.document_id);

                    if (!file.is_opened) {
                      mutation.mutate({
                        document_id: file.document_id,
                        name: "document_is_opened",
                        value: true,
                      });
                      setIsPatch(true);
                    }
                  }}
                  isFileOpened={file?.is_opened}
                  onIdClick={onIdClick}
                  onItemClick={onItemClick}
                  isDeleting={isDeleting}
                  setIsDeleting={setIsDeleting}
                  // onDeleteSuccess={(id) => {
                  //   if (id === se) {
                  //     console.log("id", id, id === file.document_id);
                  //     onItemClick(null);
                  //     onIdClick(null);
                  //   }
                  //   console.log("id 2 ", id, id === file.document_id);
                  // }}
                />
              );
            })
          : null}
      </Content>
    </Container>
  );
};

export default Sidebar;
