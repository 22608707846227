// import libraries
import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";

// import CSS file for styling
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./pdfviewerselect.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  boxesIntersect,
  useSelectionContainer,
} from "@air/react-drag-to-select";
import { uniqWith, isEqual } from "lodash";
import { Spinner } from "reactstrap";
import { ZoomIn, ZoomOut } from "lucide-react";

/** Below MouseElection method returns a Component which is responsible to generate the Drag Box as well even listener */
const MouseSelection = React.memo(
  ({ eventsElement, onSelectionChange, onSelectionEnd }) => {
    const selection = useRef();
    const dragType = useRef("NORMAL");
    const keysDown = useRef([]);

    useEffect(() => {
      const keyDownListener = (e) => {
        if (e.shiftKey) {
          if (!keysDown.current.includes("SHIFT")) {
            keysDown.current.push("SHIFT");
          }
        }
        if (e.ctrlKey || e.metaKey) {
          if (!keysDown.current.includes("CTRL")) {
            keysDown.current.push("CTRL");
          }
        }
      };
      const keyUpListener = (e) => {
        if (e.key === "Shift") {
          keysDown.current = keysDown.current.filter((key) => key !== "SHIFT");
        }
        if (e.key === "Meta" || e.key === "Control") {
          keysDown.current = keysDown.current.filter((key) => key !== "CTRL");
        }
      };

      document.addEventListener("keydown", keyDownListener);
      document.addEventListener("keyup", keyUpListener);

      return () => {
        document.removeEventListener("keydown", keyDownListener);
        document.removeEventListener("keyup", keyUpListener);
      };
    }, []);

    const { DragSelection } = useSelectionContainer({
      onSelectionChange: (box) => {
        selection.current = box;
        onSelectionChange(box, dragType.current);
      },
      onSelectionStart: () => {
        if (keysDown.current.includes("SHIFT")) {
          dragType.current = "SHIFT";
        } else if (keysDown.current.includes("CTRL")) {
          dragType.current = "CTRL";
        } else {
          dragType.current = "NORMAL";
        }
      },
      onSelectionEnd: () => {
        if (selection.current?.height >= 5) {
          onSelectionEnd(selection.current, dragType.current);
          selection.current = {};
        }
      },
      eventsElement,
    });

    return <DragSelection />;
  }
);

const PdfViewerComponentSelect = React.memo(
  ({
    documenturl,
    coordinateSaved,
    selectedfilevalename,
    selectedItem,
    selectedId,
    anotationtype,
  }) => {
    const [pdfFile, setPdfFile] = useState();
    const [imageFile, setImageFile] = useState("");
    const [pointsSaved, setPointsSaved] = useState({});
    const [currentPage, setCurrentPage] = useState(0); // Use the viewer's currentPage state
    const [zoominout, setZoominout] = useState(0); // Use the viewer's currentPage state
    const [removedclassstatus, setRemovedClassStatus] = useState(false);
    const [isTextSelectable, setIsTextSelectable] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true);

    /** ================================================================================== */
    const elementsContainerParent = useRef();
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [selectedText, setSelectedText] = useState([]);
    const myElementRef = useRef(null);

    const handleSelectionChange = useCallback((box) => {}, []);

    const handleSelectionEnd = useCallback(
      (box, dragType) => {
        let indexesToSelect = selectedIndexes.slice();
        let indexSelectedText = selectedText.slice();
        indexSelectedText = uniqWith(indexSelectedText, isEqual);
        console.log(indexSelectedText, "   indexSelectedText   ");
        const elementsContainer = document.getElementsByClassName(
          "rpv-core__text-layer"
        );
        Array.from(elementsContainer).forEach((elem, key) => {
          if (elem && elem.tagName === "DIV") {
            let firstItem = true;
            let toggleOn = true;
            Array.from(elem.childNodes).forEach((item, index) => {
              if (item.tagName === "DIV") {
                const boundingBox = item.getBoundingClientRect();
                if (boxesIntersect(box, boundingBox)) {
                  if (item.classList[0] === "hasBorder") {
                    item.parentNode.removeChild(item);
                  } else {
                    toggleOn = !indexesToSelect.includes(index);
                    if (toggleOn) {
                      indexesToSelect.push(index);
                      indexSelectedText.push({
                        page: item?.parentNode?.parentNode?.parentNode?.getAttribute(
                          "aria-label"
                        ),
                        item,
                      });
                    } else {
                      indexesToSelect = indexesToSelect.filter(
                        (idx) => idx !== index
                      );
                      indexSelectedText = uniqWith(indexSelectedText, isEqual);
                      indexSelectedText = indexSelectedText.filter((idx) => {
                        const x = document.querySelector(
                          `[aria-label= "${idx?.page}"]`
                        );
                        return (
                          x?.querySelector("." + idx?.item?.classList[1]) !==
                          item
                        );
                      });
                    }
                  }
                }
              }
            });
          }
        });

        if (indexSelectedText.length === 0) {
          const selectedColor =
            document.getElementsByClassName("selectedColor");
          while (selectedColor?.length)
            selectedColor[0].classList.remove("selectedColor");

          pointsSavedRef.current = {};
          localStorage.setItem(
            "coordinates",
            JSON.stringify(pointsSavedRef.current)
          );
        }
        setSelectedText(indexSelectedText);

        setSelectedIndexes(indexesToSelect);
      },
      [selectedIndexes, selectedText]
    );

    /** ================================================================================== */

    const pointsSavedRef = useRef({});
    useEffect(() => {
      if (selectedfilevalename == null) {
        setIsImageLoading(false);
      }
      localStorage.removeItem("coordinates");
      if (documenturl) {
        const isPdfFileEmpty = Object.keys(documenturl).length === 0;
        if (!isPdfFileEmpty) {
          setPdfFile(documenturl);
          setImageFile(documenturl);
          setTimeout(() => {
            // Set the imageFile and hide the spinner after 2 seconds
            setIsImageLoading(false);
          }, 1000);
        } else {
          setImageFile("");
          setPdfFile("");
        }
      }
      if (documenturl === null) {
        setImageFile("");
        setPdfFile("");
      }
      if (selectedId && !documenturl) {
        setPdfFile("");
        setImageFile("");
      }
    }, [documenturl, selectedId, anotationtype]);

    function createElementAtCoordinates(
      eventType,
      parentClass,
      pageInd,
      blockInd,
      x,
      y,
      x1,
      y1
    ) {
      var newElement = document.createElement("div");
      newElement.className = "borderBox" + " boxInd" + blockInd;
      // Set the style properties to position the element at the specified coordinates
      newElement.style.position = "absolute";
      newElement.style.left = x + "px";
      newElement.style.top = y + "px";
      newElement.style.width = x1 + "px";
      newElement.style.height = y1 + "px";
      var newElementborder = document.createElement("div");
      newElementborder.className = "borderBox" + " boxInd" + blockInd;
      // Set the style properties to position the element at the specified coordinates
      newElementborder.style.position = "absolute";
      newElementborder.style.left = x + "px";
      newElementborder.style.top = y + "px";
      newElementborder.style.width = x1 + "px";
      newElementborder.style.height = y1 + "px";
      // newElementborder.style.zIndex = 10;
      var parentElements = document.getElementsByClassName(parentClass);
      // var newparentElements = document.getElementsByClassName(parentClass);
      if (eventType === "onHover") {
        newElement.addEventListener("mouseover", function (event) {
          this.classList.add("hover-border");
        });
        newElement.addEventListener("mouseout", function () {
          this.classList.remove("hover-border");
        });
      } else if (eventType === "click" || eventType === "always") {
        newElement.className = "hasBorder" + " boxInd" + blockInd;
      }
      var parentElements = document.getElementsByClassName(parentClass);
      if (parentElements?.length > 0 && parentElements[Number(pageInd)]) {
        if (eventType === "click" || eventType === "always") {
          parentElements[Number(pageInd)].appendChild(newElement);
        } else if (newElement.className[0] == "b") {
          parentElements[Number(pageInd)].appendChild(newElement);
        }
        setIsTextSelectable(false);
      } else {
        // console.log("No elements found with the class name " + parentClass);
      }
    }

    const handlePointsDeleteChange = (domInd, lastDigit) => {
      if (Object.keys(pointsSavedRef.current).length > 0) {
        const newState = { ...pointsSavedRef.current };
        // Delete the specific element from the nested object
        delete newState[domInd + 1][lastDigit];
        pointsSavedRef.current = newState;
        localStorage.setItem("coordinates", JSON.stringify(newState));
      }
    };

    const clearDragSelectedItems = () => {
      const selectedColor = document.getElementsByClassName("selectedColor");
      while (selectedColor?.length > 0)
        selectedColor[0].classList.remove("selectedColor");
      setSelectedText([]);
      setSelectedIndexes([]);
    };

    const setElementPositionAndActions = (elements, parentClass) => {
      let tempPointsSaved = {}; // Temporary variable
      let pdfWidth = "";
      let pdfHeight = "";
      let pdfLeft = "";
      let pdfTop = "";

      let borderEle = document.getElementsByClassName("borderBox");
      let hasBorderEle = document.getElementsByClassName("hasBorder");

      while (borderEle.length > 0) {
        borderEle[0].parentNode.removeChild(borderEle[0]);
      }

      while (hasBorderEle.length > 0) {
        hasBorderEle[0].parentNode.removeChild(hasBorderEle[0]);
      }

      for (let domInd = 0; domInd < elements.length; domInd++) {
        var firstElement = elements[domInd];

        var rect = firstElement.getBoundingClientRect();

        // Coordinates relative to the viewport
        pdfWidth = rect.width;
        pdfHeight = rect.height;

        // add eventlistener to save coordinates on click
        elements[domInd].addEventListener("click", (event) => {
          // event.target is the element that was clicked
          var clickedElement = event.target;
          const classString = clickedElement?.classList[1]; // Get the string

          const lastDigit = Number(classString?.match(/\d+$/)[0]);
          if (!clickedElement.classList.contains("selectedColor")) {
            if (
              clickedElement?.classList[0] === "hasBorder" &&
              event.target.parentNode
            ) {
              const parentIndexOfClickedItem = Number(
                event.target.parentNode.parentNode.parentNode.parentNode.parentNode
                  .getAttribute("data-testid")
                  .split("-")[3]
              );
              const elementsToRemove = document.querySelectorAll(
                `.hasBorder.${clickedElement?.classList[1]}`
              );
              // Toggle the class when clicked
              if (
                clickedElement?.classList.contains(clickedElement?.classList[1])
              ) {
                // Remove the class if it's already present

                for (const element of elementsToRemove) {
                  element.parentNode.removeChild(element);
                  handlePointsDeleteChange(parentIndexOfClickedItem, lastDigit);

                  const newState = { ...pointsSavedRef.current };
                  // Delete the specific element from the nested object
                  delete newState[parentIndexOfClickedItem + 1][lastDigit];
                  pointsSavedRef.current = newState;
                  tempPointsSaved = newState;
                  console.log(JSON.stringify(newState), "  JSON");
                  localStorage.setItem("coordinates", JSON.stringify(newState));
                  // setPointsSaved(newState);
                }
              } else {
                console.log("else");
                // Add the class if it's not present
              }
              clearDragSelectedItems();
            }

            if (clickedElement?.classList[0] === "borderBox") {
              //pointsSavedRef.current = {};
              //localStorage.removeItem("coordinates");
              clearDragSelectedItems();
              var style = window.getComputedStyle(clickedElement);
              // You can now do something with the clicked element
              let { left, top, right, bottom, width, height } = style;
              let x1 = Number(left.split("px")[0]) / pdfWidth;
              let y1 = Number(top.split("px")[0]) / pdfHeight;
              let x2 =
                Number(width.split("px")[0]) / pdfWidth +
                Number(left.split("px")[0]) / pdfWidth;
              let y2 =
                Number(height.split("px")[0]) / pdfHeight +
                Number(top.split("px")[0]) / pdfHeight;

              let startLeft = pdfLeft + pdfWidth * x1;
              let startTop = pdfTop + pdfHeight * y1;
              let blockWidth = pdfWidth * x2 - pdfWidth * x1;
              let blockHeight = pdfHeight * y2 - pdfHeight * y1;

              createElementAtCoordinates(
                event.type,
                parentClass,
                [
                  ...event.target.parentNode.parentNode.parentNode.parentNode
                    .parentNode.childNodes,
                ].indexOf(
                  event.target.parentNode.parentNode.parentNode.parentNode
                ),
                lastDigit,
                startLeft,
                startTop,
                blockWidth,
                blockHeight
              );
              if (
                parentClass === "rpv-core__text-layer" ||
                parentClass === "imageViewerDiv"
              ) {
                let ind = 0;
                if (
                  clickedElement.className?.match(/\d+/) &&
                  clickedElement.className?.match(/\d+/)[0]
                ) {
                  ind = clickedElement.className.match(/\d+/)[0];
                } else {
                  ind = 1;
                }

                const newState = { ...pointsSavedRef.current };
                newState[
                  Number(
                    event.target.parentElement.parentElement.getAttribute(
                      "data-virtual-index"
                    )
                  ) + 1
                ] = {
                  ...newState[
                    Number(
                      event.target.parentElement.parentElement.getAttribute(
                        "data-virtual-index"
                      )
                    ) + 1
                  ],
                  [ind]: {
                    text: "",
                    bbox: [x1, y1, x2, y2],
                  },
                };
                pointsSavedRef.current = newState;
                tempPointsSaved = newState;
                localStorage.setItem("coordinates", JSON.stringify(newState));
              }
            }
          } else {
            clickedElement.classList.remove("selectedColor");
            return;
          }
        });

        if (pdfHeight != 0 && pdfWidth != 0) {
          if (anotationtype != "blocks") {
            coordinateSaved = null;
          }
          const elementIndex = Number(
            firstElement.getAttribute("data-virtual-index")
          );
          if (parentClass === "imageViewerDiv") {
            {
              coordinateSaved &&
                coordinateSaved[1] &&
                Object.keys(coordinateSaved[1]).length > 0 &&
                Object.keys(coordinateSaved[1]["blocks"]).map((ee, ii) => {
                  let [a, b, a1, b1] = coordinateSaved[1]["blocks"][ee]?.bbox;
                  let startLeft = pdfLeft + pdfWidth * a;
                  let startTop = pdfTop + pdfHeight * b;
                  let blockWidth = pdfWidth * a1 - pdfWidth * a;
                  let blockHeight = pdfHeight * b1 - pdfHeight * b;
                  if (coordinateSaved[1]["blocks"][ee]?.bbox) {
                    return createElementAtCoordinates(
                      "onHover",
                      parentClass,
                      1,
                      ee,
                      startLeft,
                      startTop,
                      blockWidth,
                      blockHeight
                    );
                  }
                });
            }
          } else {
            {
              coordinateSaved &&
                coordinateSaved[elementIndex + 1] &&
                Object.keys(coordinateSaved[elementIndex + 1]).length > 0 &&
                Object.keys(coordinateSaved[elementIndex + 1]["blocks"]).map(
                  (ee, ii) => {
                    let [a, b, a1, b1] =
                      coordinateSaved[elementIndex + 1]["blocks"][ee]?.bbox;
                    let startLeft = pdfLeft + pdfWidth * a;
                    let startTop = pdfTop + pdfHeight * b;
                    let blockWidth = pdfWidth * a1 - pdfWidth * a;
                    let blockHeight = pdfHeight * b1 - pdfHeight * b;
                    if (coordinateSaved[elementIndex + 1]["blocks"][ee]?.bbox) {
                      return createElementAtCoordinates(
                        "onHover",
                        parentClass,
                        domInd,
                        ee,
                        startLeft,
                        startTop,
                        blockWidth,
                        blockHeight
                      );
                    }
                  }
                );
            }
          }
        }
        var selectedcoordinates = JSON.parse(
          localStorage.getItem("coordinates")
        );
        if (selectedcoordinates != null) {
          const elementIndex = Number(
            firstElement.getAttribute("data-virtual-index")
          );
          selectedcoordinates[elementIndex + 1] &&
            Object.keys(selectedcoordinates[elementIndex + 1])
              .filter((ee) => selectedcoordinates[elementIndex + 1][ee]?.bbox)
              .forEach((ee, ii) => {
                let [a, b, a1, b1] =
                  selectedcoordinates[elementIndex + 1][ee]?.bbox;
                let startLeft = pdfLeft + pdfWidth * a;
                let startTop = pdfTop + pdfHeight * b;
                let blockWidth = pdfWidth * a1 - pdfWidth * a;
                let blockHeight = pdfHeight * b1 - pdfHeight * b;
                createElementAtCoordinates(
                  "always",
                  parentClass,
                  domInd,
                  ee,
                  startLeft,
                  startTop,
                  blockWidth,
                  blockHeight
                );
              });
        }
      }
    };

    let timer = null;
    const handlePageChange = (page) => {
      /* setSelectedText(selectedText);
      setSelectedIndexes(selectedIndexes); */
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setCurrentPage(page.currentPage);
      }, 500);
    };

    useEffect(() => {
      if (selectedItem == "bounding-box") {
        if (selectedfilevalename?.document_type === ".pdf") {
          pointsSavedRef.current = pointsSaved;
          var elements = document.getElementsByClassName(
            "rpv-core__page-layer"
          );
          if (elements?.length > 0) {
            setElementPositionAndActions(elements, "rpv-core__text-layer");
          } else {
            setTimeout(() => {
              setElementPositionAndActions(elements, "rpv-core__text-layer");
            }, 5000);
          }
        } else if (
          selectedfilevalename?.document_type === ".png" ||
          selectedfilevalename?.document_type === ".jpg" ||
          selectedfilevalename?.document_type === ".jpeg"
        ) {
          var elements = document.getElementsByClassName("imageViewerDiv");
          if (elements.length > 0) {
            setElementPositionAndActions(elements, "imageViewerDiv");
          } else {
            setTimeout(() => {
              setElementPositionAndActions(elements, "imageViewerDiv");
            }, 5000);
          }
        }
      }
    }, [selectedItem, currentPage, zoominout, coordinateSaved, anotationtype]);

    const renderToolbar = (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            Download,
            EnterFullScreen,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Print,
            Zoom,
            ZoomIn,
            ZoomOut,
            ShowSearchPopover,
          } = slots;
          return (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                backgroundColor: "transparent",
                gap: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "0px 50px",
                  alignItems: "center",
                }}
              >
                <ShowSearchPopover />
                <div style={{ padding: "0px 2px" }}>
                  <GoToPreviousPage />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 2px",
                    color: "#9A9595",
                    minWidth: "100px",
                    fontSize: "14px",
                  }}
                >
                  <CurrentPageInput /> of <NumberOfPages />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToNextPage />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Zoom />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ padding: "0px 2px" }}>
                  <EnterFullScreen />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Download />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Print />
                </div>
              </div>
            </div>
          );
        }}
      </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
      renderToolbar,
    });

    useEffect(() => {
      if (selectedText.length > 0) {
        const selectedColor = document.getElementsByClassName("selectedColor");
        while (selectedColor?.length)
          selectedColor[0].classList.remove("selectedColor");
        const grpByPage = Object.groupBy(selectedText, ({ page }) => page);
        const newState = {};
        for (let param in grpByPage) {
          const pageIndex = parseInt(param.split(" ")[1]);
          const pdfEleme = document.querySelector(
            `[data-virtual-index = "${pageIndex}"]`
          );
          const pdfWidth = pdfEleme?.getBoundingClientRect()?.width;
          const pdfHeight = pdfEleme?.getBoundingClientRect()?.height;

          const obj = {};

          grpByPage[param].forEach((elem) => {
            var style = window.getComputedStyle(elem.item);
            // You can now do something with the clicked element
            let { left, top, right, bottom, width, height } = style;
            let x1 = Number(left.split("px")[0]) / pdfWidth;
            let y1 = Number(top.split("px")[0]) / pdfHeight;
            let x2 =
              Number(width.split("px")[0]) / pdfWidth +
              Number(left.split("px")[0]) / pdfWidth;
            let y2 =
              Number(height.split("px")[0]) / pdfHeight +
              Number(top.split("px")[0]) / pdfHeight;
            const ind = parseInt(elem.item.classList[1].split("boxInd")[1]);

            obj[ind] = {
              text: "",
              bbox: [x1, y1, x2, y2],
            };
          });
          newState[parseInt(param.split(" ")[1])] = obj;
        }
        pointsSavedRef.current = newState;
        localStorage.setItem(
          "coordinates",
          JSON.stringify(pointsSavedRef.current)
        );

        selectedText.forEach((elem, index) => {
          const x = document.querySelector(`[aria-label= "${elem?.page}"]`);
          x?.querySelector("." + elem?.item?.classList[1])?.classList?.add(
            "selectedColor"
          );
        });
        // setSelectedIndexes(selectedIndexes);
        setSelectedText(selectedText);
      }
    }, [selectedText, currentPage]);

    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
      setZoomLevel((prevZoom) => prevZoom + 0.1);
    };

    const handleZoomOut = () => {
      setZoomLevel((prevZoom) => Math.max(0.1, prevZoom - 0.1));
    };

    const imageStyle = {
      transform: `scale(${zoomLevel})`,
      transition: "transform 0.2s ease-in-out",
      maxWidth: "100%",
      maxHeight: "100%",
    };
    return (
      <div style={{ height: "100%" }}>
        <div className="pdf-name">
          {selectedfilevalename?.file_name}
          {selectedId !== null &&
          selectedfilevalename?.document_type !== ".pdf" &&
          selectedfilevalename?.document_type !== ".txt" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginRight: "10px",
              }}
            >
              <button onClick={handleZoomIn} className="zoom-button">
                <ZoomIn size={10} /> {"Zoom In"}
              </button>
              <button onClick={handleZoomOut} className="zoom-button">
                <ZoomOut size={10} /> {"Zoom Out"}
              </button>
            </div>
          ) : null}
        </div>

        <div
          className="pdf-container pdf-containerselect"
          style={{ height: "94%" }}
        >
          {selectedfilevalename?.document_type === ".pdf" ? (
            <>
              {!pdfFile && (
                <Spinner
                  className="m-1 spinner-loader"
                  color="secondary"
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              )}
              {pdfFile && (
                <>
                  <div
                    className="rpv-core__viewer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={pdfFile}
                        plugins={[defaultLayoutPluginInstance]}
                        defaultPage={currentPage}
                        theme={{
                          theme: "light",
                        }}
                        onPageChange={(e) => handlePageChange(e)}
                        onZoom={(e) => {
                          setZoominout(e.scale);
                          setRemovedClassStatus(true);
                        }}
                        renderLoader={() => (
                          <Spinner
                            className="m-1 spinner-loader"
                            color="secondary"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        )}
                      />
                    </Worker>
                    {/* </div> */}
                    {selectedItem == "bounding-box" &&
                      document.getElementsByClassName(`Pane1`)[0]?.childNodes[0]
                        ?.childNodes[0] &&
                      ReactDOM.createPortal(
                        <MouseSelection
                          onSelectionChange={handleSelectionChange}
                          onSelectionEnd={handleSelectionEnd}
                          eventsElement={
                            document.getElementsByClassName(`Pane1`)[0]
                              ?.childNodes[0]?.childNodes[0]
                          }
                        />,
                        document.getElementsByClassName(`Pane1`)[0]
                          ?.childNodes[0]?.childNodes[0]
                      )}
                  </div>
                </>
              )}{" "}
            </>
          ) : (
            <div className="imageViewerDiv">
              {isImageLoading ? <Spinner /> : ""}
              {imageFile ? (
                <>
                  <img
                    src={imageFile}
                    alt={"selected File"}
                    style={imageStyle}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="pdfline-saprator"></div>
      </div>
    );
  }
);

export default PdfViewerComponentSelect;
