import { useState } from "react";
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import { useMutation, useQuery, useQueryClient } from "react-query";

const BillingPreferences = () => {
  const [billing, setBilling] = useState();
  const [billingUpdateAlert, setBillingUpdateAlert] = useState({
    show: false,
    message: "",
  });

  const [showSubmit, setShowSubmit] = useState(false);

  const updateBillingField = (fieldName, newValue) => {
    setShowSubmit(true);
    setBilling({
      ...billing,
      [fieldName]: newValue,
    });
  };

  const submitbilling = () => {
    setShowSubmit(false);
    const data = {
      billing_details: {
        billing_name: billing.billing_name,
        billing_po: billing.billing_po,
        billing_email: billing.billing_email,
        billing_line1: billing.billing_line1,
        billing_line2: billing.billing_line2,
        billing_city: billing.billing_city,
        billing_state: billing.billing_state,
        billing_postal_code: billing.billing_postal_code,
        billing_country: billing.billing_country,
        billing_tax_type: billing.billing_tax_type,
        billing_tax_id: billing.billing_tax_id,
      },
    };

    mutation.mutate(data);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(authDataObject.createBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries("billing-preference");
      setBillingUpdateAlert({
        show: true,
        message: "Billing details updated successfully",
      });
    },
    onError: () => {
      setBillingUpdateAlert({
        show: true,
        message: "Billing details updated failed",
      });
    },
  });

  const { data: billingData, isFetching } = useQuery(
    [`billing-preference`],
    authDataObject.getbilling
  );

  useEffect(() => {
    if (billingData) {
      setBilling(billingData.data.details.user_billing_data);
    }
  }, [billingData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {isFetching || mutation.isLoading ? (
        <div
          style={{
            height: "85%",
            width: "90%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      ) : null}
      <h1 className="subtitle">Billing preferences</h1>
      <div style={{ marginTop: 4 }}>
        <span className="belowstyle">
          Changes to these preferences will apply to future invoices only. If
          you need a past invoice reissued, please contact
          contact@docudiveai.com.
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="space inputdiv2">
          {billing?.billing_name ? <p className="inputhead">{"Name"}</p> : []}
          <input
            value={billing?.billing_name}
            className="input"
            onChange={(e) => updateBillingField("billing_name", e.target.value)}
            type="text"
          />
          {billing?.billing_name ? (
            []
          ) : (
            <p className="floating-label">{"Name"}</p>
          )}
        </div>

        <span className="belowstyle2">
          If specified, this name will appear on invoices instead of your
          organization name.
        </span>
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="space inputdiv2">
          <input
            value={billing?.billing_po}
            className="input"
            onChange={(e) => updateBillingField("billing_po", e.target.value)}
            type="text"
          />

          {billing?.billing_po ? (
            []
          ) : (
            <p className="floating-label">{"Purchase Order(PO)"}</p>
          )}
        </div>

        <span className="belowstyle2">
          Your PO number will be displayed on future invoices.
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="space inputdiv2">
          {billing?.billing_email ? (
            <p className="inputhead">{"Billing Email"}</p>
          ) : (
            []
          )}
          <input
            value={billing?.billing_email}
            className="input"
            onChange={(e) =>
              updateBillingField("billing_email", e.target.value)
            }
            type="text"
          />
          {billing?.billing_email ? (
            []
          ) : (
            <p className="floating-label">{"Billing Email"}</p>
          )}
        </div>

        <span className="belowstyle2">
          Invoices and other billing notifications will be sent here (in
          addition to being sent to the owner).
        </span>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <span className="primary">Primary Address </span>
        <span className="primaryaddress">
          This is the physical address of the company purchasing OpenAI services
          and is used to calculate any applicable sales tax.
        </span>
      </div>

      <div className="space inputdiv2">
        {billing?.billing_country ? (
          <p className="inputhead">{"Country"}</p>
        ) : (
          []
        )}
        <input
          value={billing?.billing_country}
          className="input"
          onChange={(e) =>
            updateBillingField("billing_country", e.target.value)
          }
          type="text"
        />
        {billing?.billing_country ? (
          []
        ) : (
          <p className="floating-label">{"Country"}</p>
        )}
      </div>

      <div className="space inputdiv2">
        {billing?.billing_line1 ? (
          <p className="inputhead">{"Address line 1"}</p>
        ) : (
          []
        )}
        <input
          value={billing?.billing_line1}
          className=" input "
          onChange={(e) => updateBillingField("billing_line1", e.target.value)}
          type="text"
        />
        {billing?.billing_line1 ? (
          []
        ) : (
          <p className="floating-label">{"Address line 1"}</p>
        )}
      </div>

      <div className="space inputdiv2">
        {billing?.billing_line2 ? (
          <p className="inputhead">{"Address line 2"}</p>
        ) : (
          []
        )}
        <input
          value={billing?.billing_line2}
          className=" input "
          onChange={(e) => updateBillingField("billing_line2", e.target.value)}
          type="text"
        />
        {billing?.billing_line2 ? (
          []
        ) : (
          <p className="floating-label">{"Address line 2"}</p>
        )}
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "40%",
          justifyContent: "space-between",
        }}
      >
        <div className="space inputdiv3">
          {billing?.billing_city ? <p className="inputhead">{"City"}</p> : []}
          <input
            value={billing?.billing_city}
            className=" input "
            onChange={(e) => updateBillingField("billing_city", e.target.value)}
            type="text"
          />
          {billing?.billing_city ? (
            []
          ) : (
            <p className="floating-label">{"City"}</p>
          )}
        </div>

        <div className="space inputdiv3">
          {billing?.billing_state ? <p className="inputhead">{"State"}</p> : []}
          <input
            value={billing?.billing_state}
            className=" input "
            onChange={(e) =>
              updateBillingField("billing_state", e.target.value)
            }
            type="text"
          />
          {billing?.billing_state ? (
            []
          ) : (
            <p className="floating-label">{"State"}</p>
          )}
        </div>

        <div className="space inputdiv3">
          {billing?.billing_postal_code ? (
            <p className="inputhead">{"Post code"}</p>
          ) : (
            []
          )}
          <input
            value={billing?.billing_postal_code}
            className=" input "
            onChange={(e) =>
              updateBillingField("billing_postal_code", e.target.value)
            }
            type="text"
          />
          {billing?.billing_postal_code ? (
            []
          ) : (
            <p className="floating-label">{"Post code"}</p>
          )}
        </div>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <span className="primary">Business tax ID </span>
        <span className="primaryaddress">
          If you are a business tax registrant, please enter your business tax
          ID here.
        </span>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "40%",
          justifyContent: "space-between",
        }}
      >
        <div className="space inputdiv3" style={{ width: "42%" }}>
          <input
            value={billing?.billing_tax_type}
            className=" input "
            onChange={(e) =>
              updateBillingField("billing_tax_type", e.target.value)
            }
            type="text"
          />

          {billing?.billing_tax_type ? (
            []
          ) : (
            <p className="floating-label">{"Business tax type"}</p>
          )}
        </div>
        <div className="space inputdiv3" style={{ width: "54%" }}>
          <input
            value={billing?.billing_tax_id}
            className=" input "
            onChange={(e) =>
              updateBillingField("billing_tax_id", e.target.value)
            }
            type="text"
          />

          {billing?.billing_tax_id ? (
            []
          ) : (
            <p className="floating-label">{"ID"}</p>
          )}
        </div>
      </div>
      <div className="buttonContainer">
        <button
          disabled={!showSubmit}
          className="buttonstyle"
          onClick={() => submitbilling()}
        >
          Save Changes
        </button>
      </div>
      {billingUpdateAlert?.show && (
        <ConfirmationAlertModal
          isOpen={billingUpdateAlert?.show}
          handleClose={() => {
            setBillingUpdateAlert({
              show: false,
              message: "",
            });
          }}
          title={billingUpdateAlert?.message}
        />
      )}
    </div>
  );
};

export default BillingPreferences;
