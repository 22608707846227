import React, { useMemo } from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import ExoprtIcon from "../assets/images/export_file_icon.svg";
import GeneratePdf from "../components/Commoncomponent/GeneratePdf/GeneratePdf";
import { useEffect } from "react";
import authDataObject from "../services/Auth_Controller/Auth_Controller";
import { ChevronDown, ChevronUp } from "lucide-react";

const ExportDropDown = ({
  selectedId,
  isEnable,
  // chat_conversation,
  selectedFileName,
  chattype,
  annotations,
  annotationsBusiness,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isOpenDownloadModal, setOpenDownloadModal] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const [openAccordion, setOpenAccordion] = useState("1");
  const toggleAccordion = (id) => {
    if (openAccordion === id) {
      setOpenAccordion();
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-container"
        isOpen={isOpen}
        toggle={() => toggleDropdown()}
      >
        <DropdownToggle
          caret
          className="icon-text-container"
          disabled={!isEnable}
          style={{
            cursor: isEnable ? "pointer" : "default",
            gap: isOpen ? "27px" : "8px",
          }}
        >
          <img src={ExoprtIcon} alt="export icon" />
          <span className="text">{"Export"}</span>
        </DropdownToggle>
        <DropdownMenu className="drop-down-menu-style">
          <DropdownItem className="disable-item" disabled>
            Export options
          </DropdownItem>
          <div className="drop-down-item-style">
            <GeneratePdf
              pdfname={selectedFileName}
              chatmode={chattype}
              name="Chat history"
            />
          </div>
          <Accordion
            open={openAccordion}
            toggle={(val) => toggleAccordion(val)}
          >
            <AccordionItem>
              <AccordionHeader
                targetId="1"
                style={{
                  padding: "6px 5px",
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: 0,
                }}
              >
                Essentials information{" "}
                {openAccordion === "1" ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </AccordionHeader>
              {annotations &&
                annotations?.map(
                  (item, index) =>
                    item.status === "completed" && (
                      <AccordionBody
                        key={index}
                        accordionId="1"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className="drop-down-item-style"
                          style={{
                            margin: 4,
                            cursor: "pointer",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "4px 15px",
                          }}
                          onClick={() => {
                            if (item.status === "completed") {
                              setSelectedAnnotation(item.annotation_type);

                              setOpenDownloadModal(true);
                              setDisplayName(
                                item.display_name.replace(/_/g, " ")
                              );
                            }
                          }}
                        >
                          {item.display_name?.replace(/_/g, " ")}
                        </div>
                      </AccordionBody>
                    )
                )}
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="2"
                style={{
                  padding: "6px 5px",
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: 0,
                }}
              >
                Business Insights{" "}
                {openAccordion === "2" ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </AccordionHeader>
              {annotationsBusiness &&
                annotationsBusiness?.map(
                  (item, index) =>
                    item.status === "completed" && (
                      <AccordionBody
                        key={index}
                        accordionId="2"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className="drop-down-item-style"
                          style={{
                            margin: 4,
                            cursor: "pointer",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "4px 10px",
                          }}
                          onClick={() => {
                            if (item.status === "completed") {
                              setSelectedAnnotation(item.annotation_type);

                              setOpenDownloadModal(true);
                              setDisplayName(
                                item.display_name.replace(/_/g, " ")
                              );
                            }
                          }}
                        >
                          {item.display_name?.replace(/_/g, " ")}
                        </div>
                      </AccordionBody>
                    )
                )}
            </AccordionItem>
          </Accordion>
        </DropdownMenu>
      </Dropdown>
      {isOpenDownloadModal && (
        <DownloadModal
          selectedId={selectedId}
          selectedAnnotation={selectedAnnotation}
          handleClose={() => {
            setOpenDownloadModal(false);
            setSelectedAnnotation(null);
            setIsOpen(false);
          }}
          selectedFileName={selectedFileName}
          displayName={displayName}
        />
      )}
    </>
  );
};

export default ExportDropDown;

const DownloadModal = ({
  selectedId,
  selectedAnnotation,
  handleClose,
  selectedFileName,
  displayName,
}) => {
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfGenerated) {
      downloadPdf();
    }
  }, [pdfGenerated]);

  const timeZoneOffset = useMemo(() => {
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const sign = offset > 0 ? "-" : "+";
    const formattedOffset = `${sign}${offsetHours}${
      offsetMinutes > 0
        ? "." + (offsetMinutes / 60).toFixed(1).substring(2)
        : ""
    }`;

    return formattedOffset;
  }, []);

  const downloadPdf = async () => {
    const res = await authDataObject.exportPDF({
      document_id: selectedId,
      annotation_type: selectedAnnotation,
      offset: timeZoneOffset ? timeZoneOffset : "+00.00",
    });
    if (res.status === 200) {
      setIsLoading(false);
    }
    let url = res.data?.details?.presigned_url;
    const link = document.createElement("a");
    link.href = url;
    link.download = `${selectedFileName
      ?.split(".")
      .slice(0, -1)
      .join(".")}-${displayName.replace(/ /g, "_")}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading(false);
    handleClose(); // Optionally close the modal after download
  };

  const fetchData = async () => {
    setIsLoading(true);
    setPdfGenerated(true);
  };

  return isLoading ? (
    <div
      style={{
        height: "100vh",
        width: " 100%",
        position: "fixed",
        top: 65,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: "1050",
        background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(1.5px)",
      }}
    >
      <Spinner
        className="m-1 spinner-loader"
        color="secondary"
        style={{
          width: "50px",
          height: "50px",
          zIndex: "102",
        }}
      />
      <span
        style={{
          color: "#6c757d",
        }}
      >
        Generating...
      </span>
    </div>
  ) : null;
};
